.centered-column{

    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    align-items: center;

}

.-button {
    padding: 15px 20px;
    display: flex;
    text-align: center;
    cursor: pointer;
}

.deck-icon{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centered-row{
    display: flex;
    justify-content: center;
}