.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.page-container {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  top: 0;
}


.page-container::after {
  background-image: url(http://spaceengine.org/media/2016/05/Stars02.png);
  background-size: cover;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* opacity: 0.2; */
  width: 100%;
  height: 100%;
  background-position: center;
}

.navBar {
  width: 100%;
  height: 50px;
  background: black;
  border-bottom: 8px solid white;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  box-shadow: 1px 1px 3px black;
  position: absolute;
}
.navBar nav {
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 890px;
  position: relative;
}

.iconContainer {
  display: flex;
  width: 15%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 10px;
}

.iconContainer i {
  cursor: pointer;
}

