.tests-page-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 50px;
}

.sections-container {
  width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}


.tests-sec1 {
  width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.test-all-btn {
}

.test-session-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 350px;
  overflow: scroll;
}

.test-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 5px;
}

.test-button {
}

.test-word-count{
  display: flex;
  color: black;
  margin: 4px 4px;
  width: 2em;
  height: 2em;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 900;
  border-radius: 50%;
  border: 2px solid green;
  -webkit-user-select: none;
  user-select: none;
}

.test-word-count:hover{
  background: green;
  color: white;
}

.test-word-count.clicked{
  background: green;
  color: white;
 
}

.words-due{
  color: red;
  font-weight: bold;
}

