.note-taker{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: rgb(46, 46, 46);
}

.pad-container{
    position: relative;
    height: 80%;
    width: 80%;
    background: rgb(95, 95, 95);
    border: 1px solid rgb(119, 119, 119);
    display: flex;
    flex-direction: column;
    border-radius: 10px;  
}

.line-container {
    height: 100%;
    width: 50%;
    background: rgb(95, 95, 95);
    border: 1px solid rgb(119, 119, 119);
    display: flex;
    flex-direction: column; 
}

.line-input {
 height: 100%;
 width: 100%;
 background: unset;
 border: unset;
 display: none;
 background: white;
}

.line-input.active {
    display: flex;
}

.pad-row{
    border-bottom: 1px solid rgb(0, 185, 218);
    /* height: 10vh; */
    width: 100%;
    position: relative;
    display: flex;
}

.line-block{
    height: 98%;
    border-right: 1px solid rgb(0, 185, 218);
    cursor: pointer;
    position: relative;
}

/* .line-block.hov:hover {
    background: rgb(182, 182, 182);
    border: rgb(255, 255, 96);
} */