.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.page-container {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  top: 0;
}

.page-container::after {
  background-image: url(http://spaceengine.org/media/2016/05/Stars02.png);
  background-size: cover;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* opacity: 0.2; */
  width: 100%;
  height: 100%;
  background-position: center;
}

.sidePanels {
  display: none;
  width: 30%;
  justify-content: center;
  align-items: center;
}
.statsBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  font-weight: 100;
  height: 40%;
}

.statHolder {
  height: 5%;
  min-height: 50px;
  min-width: 160px;
}

.statName {
  display: flex;
}

.statBarHolder {
  height: 25px;
  display: flex;
}

.statBar {
  height: 60%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}


.iconContainer {
  display: flex;
  width: 15%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 10px;
}

.iconContainer i {
  cursor: pointer;
}

.logo-txt {
  margin-left: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  color: white;
  text-shadow: 1px 1px 0px black;
  cursor: pointer;
}

.side-bar {
  position: absolute;

  display: flex;
  flex-direction: column;
  height: 94.4%;
  width: 50%;
  background: white;
  top: 50px;
  right: 0;
  z-index: 10;
  border-right: 1px black solid;
  border-bottom: 1px black solid;
  border-bottom-right-radius: 200px;
  transition: 1s;
}

.shown--sidebar {
  display: flex;
  opacity: 1;
  transition: 1s;
  right: 0;
}
.side-bar-header {
  width: 100%;
  display: flex;
  z-index: 10;
  box-shadow: 1px 1px 0.1px black;
  align-items: center;
}

.sideBarBackground {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 99.9%;
  max-width: 700px;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  background: rgba(224, 224, 224, 0.1);
  z-index: 9;
}

.dbl-right {
  margin: 5px;
  z-index: 10;
  align-self: center;
  cursor: pointer;
  position: relative;
  top: 2px;
}

svg[data-icon="double-right"]:hover {
  fill: rgb(255, 91, 69);
}

.side-bar-header h1 {
  margin: unset;
}

.items-container {
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.exHolder {
  display: flex;
  margin: 5px;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
}

.exWordContainer {
  font-weight: bold;
  width: 100%;
  display: flex;
  user-select: none;
  background: rgba(57, 127, 255, 0.767);
  border-top-right-radius: 20px;
}
svg[data-icon="caret-right"] {
  margin-right: 2px;
  cursor: pointer;
}
svg[data-icon="caret-right"]:hover {
  fill: rgb(255, 91, 69);
}

.exampleStar {
  margin: 5px 0 0 5px;
}

.exStar {
  cursor: pointer;
}

.exampleStar svg[data-icon="star"] {
  cursor: pointer;
  position: relative;
  height: 15px;
  width: 15px;
  fill: rgb(255, 91, 69);
}

.deleteExText {
  color: black;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  position: relative;
  transition: 1s;
}

.exDefinitionContainer {
  font-style: italic;
  width: 100%;
  display: flex;
  background: rgb(212, 212, 212);
  border-bottom-left-radius: 20px;
}

.exDefinition {
  margin-left: 19px;
  margin-bottom: 3px;
  color: dimgray;
  text-align: left;
}

.card-container {
  position: relative;
  display: flex;
  padding-top: 50px;
  width: 90%;
  justify-content: center;
  align-items: center;
  max-width: 890px;
  background: rgba(75, 75, 75, 0.459);
  z-index: 1;
}

.card-container::after {
  background-image: url("https://images.pexels.com/photos/1439226/pexels-photo-1439226.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"); 
  background-size: cover;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  /* border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px; */
  z-index: -1;
}

.btn-container {
  display: flex;
  justify-content: center;
  position: relative;
  width: 330px;
  margin: 10px 0 25px 0;
}

.btn-container.mobile {
  display: none;
}

@media screen and (max-width: 415px) {
  .btn-container.mobile {
    display: flex;
  }
  .btn-container.desktop {
    display: none;
  }
}

.btn-container div {
}

.statusBarCont {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.statusBar {
  width: 50%;
  max-width: 300px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  position: relative;
}
.anticon-star {
  justify-self: flex-end;
  cursor: pointer;
}

.statusBarStar {
  position: relative;
  top: 40px;
  z-index: 5;
}

.statusBarStar svg[data-icon="star"] {
  height: 30px;
  width: 30px;
  fill: rgb(255, 223, 42);
}
.statusBarStar svg[data-icon="star"]:hover {
  fill: rgb(68, 118, 255);
}

.stats {
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  z-index: -3;
}

.cardBtn {
  color: white;
  font-size: 20px;
  font-weight: 200;
  width: 50%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 3px solid white;
}

.green {
  background: rgb(0, 197, 26);
  border-right-width: 1.5px;
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
}

.red {
  background: rgb(255, 91, 69);
  border-left-width: 1.5px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

.red:hover {
  background: rgb(255, 91, 69, 0.432);
}

.green:hover {
  background: rgba(0, 197, 26, 0.432);
}

.instructionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
 
}

.arrowContainer {
  display: flex;
  width: 80%;
  justify-content: space-around;
}

.insTitle {
  color: rgb(182, 182, 182);
  font-size: 17px;
}

.arrows svg {
  fill: white;
  height: 20px;
  width: 20px;
}
.instBox {
  display: flex;
  flex-direction: column;
}

.instr {
  color: rgb(182, 182, 182);
  font-size: 12px;
}

.modal-bg{
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  overflow: hidden;
  justify-content: center;
  align-items: center;
   
}
.modal-bg::before{
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  overflow: hidden;
  content: "";
  top: 0;
  right: 0;
  background: white;
  opacity: .3;
  filter: blur(150px);
}

.left-section{
  height: 100%;
  display: flex;
  justify-content: center;
  width: 30%;
  align-items: center;
}
.center-section{
width: 50%;
}
.right-section{
  height: 100%;
  display: flex;
  justify-content: center;
  width: 30%;
  align-items: center;
}

.complete-statbar{
  border: 4px solid gray;
  opacity: .5;
}
.complete-stat-text{
  color: gray;
  opacity: .5;
}

.left-section-content1{

}


@media screen and (max-width: 770px) {
  .statsBox {
    position: relative;
    order: 1;
    align-self: center;
    background: rgba(0, 0, 0, 0.3);
    width: 90%;
    border: 2px solid;
    padding: 10px;
    top: unset;
    left: unset;
    margin: 15px 0;
    border-radius: 5px;
  }
}
