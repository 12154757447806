.card-review-container{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.session-card-container{
    padding: 15px;
    height: 15%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cards-viewer{
    display: flex;
    width: 50%;
    height: 50%;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    align-items: center;
}

.card-review-card{
    border: 1px solid black;
    border-radius: 8px;
    padding: 5px;
    width: 25%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 8px 8px;
}

.exit{
    position: absolute;
    top: 0;
    right: 0; 
    cursor: pointer;
}