.left-section{

}

.test-results-container{

}

.test-result-box{

}

.remembered-count-container{

}

.forgotten-count-container{

}
.main-test-section{
    display: flex;
    justify-content: center;
}
.save-quit-button{
    
}