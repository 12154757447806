.mkStarCont {
    display: flex;
    position: absolute;
    margin: 0 auto;
    height: 380px;
    width: 200px;
    border-radius: 20px;
    border: 1px solid black;
    cursor: pointer;
    background: black;
    top: 0;
    align-items: flex-end;
    justify-content: center;
    z-index: 4;
  }
  
  .card {
    display: flex;
    perspective: 500;
    position: relative;
    margin: 0 auto;
    height: 350px;
    min-height: 350px;
    width: 200px;
    border-radius: 20px;
    border: 1px solid black;
    cursor: pointer;
    transform-style: preserve-3d;
    transition: 0.6s;
    background: white;
    z-index: 5;
  }
  .basic-card {
    display: flex;
    
    position: relative;
    height: 100px;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    border-radius: 20px;
    border: 1px solid black;
    cursor: pointer;
    background: white;
    z-index: 5;
  }
  .audioExampleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  
  .front,
  .back {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: all 0.6s ease-in-out;
    user-select: none;
    font-family: "Sawarabi Gothic", sans-serif;
    font-size: 20px;
  }
  
  .back {
    transform: rotateY(180deg);
  }
  .front {
    flex-direction: column;
  }
  
  .flip {
    transform: rotateY(180deg);
  }
  
  .cardHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .audioExampleText {
    cursor: pointer;
    padding: 0 10px;
    min-height: 25px;
    color: white;
    font-size: 20px;
    border-radius: 25px;
    box-shadow: 1px, 1px, 1px, black;
    margin-bottom: 0px;
    text-shadow: 1px 1px 0px black;
  }
  
  .audioExampleBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80px;
  }
  
  .audioExampleBox p {
    margin: unset;
  }

  .card.wide > .front, .back{
      font-size: 12px;
  }

  .card.wide{
    width: 100%;
  }
  
  .translation {
    font-style: italic;
    transition: 1s;
  
    color: rgb(211, 211, 211);
    font-size: 18px;
    margin-top: 0px;
    border-radius: unset;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 1px solid antiquewhite;
    border-top: unset;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    max-width: 90%;
    padding: 0 10px;
  }
  
  .front.anticon-star {
    justify-self: flex-end;
    cursor: pointer;
    align-self: flex-end;
    margin: 10px;
  }
  
  .mkStarCont svg[data-icon="star"] {
    cursor: pointer;
    position: relative;
    height: 20px;
    width: 20px;
    fill: rgb(255, 223, 42);
  }
  
  .mkStarCont svg[data-icon="star"]:hover {
    fill: rgb(255, 91, 69);
  }
  
  .markerStar {
    justify-self: flex-end;
    align-self: flex-end;
  }
  
  .wordPlayer {
    margin: 35px 0 10px 0;
  }
  
  .starSelect svg[data-icon="star"] {
    fill: rgb(255, 91, 69);
  }

  .see-choices{

  }

  .answer-block{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    position: absolute;
    bottom: -10px;
    left: -10%;
    width: 110%;
    /* width: 110%; */
    background: gainsboro;
    padding: 10px;
  }

 .answer-block.hide {
      display: none;
  } 
  
  @media screen and (max-height: 900px) {
    .page-container {
      height: unset;
      min-height: 100vh;
    }
    .statusBarCont {
      margin-top: 8%;
    }
  }
  
  @media screen and (max-width: 415px) {
    .page-container {
      min-height: 100vh;
      height: unset;
    }
    .btn-container {
      width: 95%;
      margin: 10px 0 5px 0;
    }
    .audioExampleBox {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 10px;
      margin: 5px 5px;
    }
    .card-container {
      border-radius: unset;
      width: 95%;
    }
    .card-container::after {
      border-radius: unset;
    }
    .instructionContainer {
      display: none;
    }
    .statusBarCont {
      margin-top: 18%;
    }
    .statusBarStar {
      top: 0;
    }
    .navBar nav {
      justify-content: space-between;
      width: 90%;
    }
    .logo-txt {
      margin: unset;
    }
  }
  
  .hidden {
    display: none;
    transition: 1s;
  }
  .btn-container.hidden {
    display: none;
    transition: 1s;
  }
  