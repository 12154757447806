body {
  margin: 0;
  padding: 0;
  font-family: 'Sawarabi Gothic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.page-container {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  top: 0;
}


.page-container::after {
  background-image: url(http://spaceengine.org/media/2016/05/Stars02.png);
  background-size: cover;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* opacity: 0.2; */
  width: 100%;
  height: 100%;
  background-position: center;
}

.navBar {
  width: 100%;
  height: 50px;
  background: black;
  border-bottom: 8px solid white;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  box-shadow: 1px 1px 3px black;
  position: absolute;
}
.navBar nav {
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 890px;
  position: relative;
}

.iconContainer {
  display: flex;
  width: 15%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 10px;
}

.iconContainer i {
  cursor: pointer;
}


.mkStarCont {
  display: flex;
  position: absolute;
  margin: 0 auto;
  height: 380px;
  width: 200px;
  border-radius: 20px;
  border: 1px solid black;
  cursor: pointer;
  background: black;
  top: 0;
  align-items: flex-end;
  justify-content: center;
  z-index: 4;
}

.card {
  display: flex;
  -webkit-perspective: 500;
          perspective: 500;
  position: relative;
  margin: 0 auto;
  height: 350px;
  min-height: 350px;
  width: 200px;
  border-radius: 20px;
  border: 1px solid black;
  cursor: pointer;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: 0.6s;
  background: white;
  z-index: 5;
}
.audioExampleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.front,
.back {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: all 0.6s ease-in-out;
  -webkit-user-select: none;
          user-select: none;
  font-family: "Sawarabi Gothic", sans-serif;
  font-size: 20px;
}

.back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.front {
  flex-direction: column;
}

.flip {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.cardHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.audioExampleText {
  cursor: pointer;
  padding: 0 10px;
  min-height: 25px;
  color: white;
  font-size: 20px;
  border-radius: 25px;
  box-shadow: 1px, 1px, 1px, black;
  margin-bottom: 0px;
  text-shadow: 1px 1px 0px black;
}

.audioExampleBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80px;
}

.audioExampleBox p {
  margin: unset;
}

.translation {
  font-style: italic;
  transition: 1s;

  color: rgb(211, 211, 211);
  font-size: 18px;
  margin-top: 0px;
  border-radius: unset;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 1px solid antiquewhite;
  border-top: unset;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  max-width: 90%;
  padding: 0 10px;
}

.front.anticon-star {
  justify-self: flex-end;
  cursor: pointer;
  align-self: flex-end;
  margin: 10px;
}

.mkStarCont svg[data-icon="star"] {
  cursor: pointer;
  position: relative;
  height: 20px;
  width: 20px;
  fill: rgb(255, 223, 42);
}

.mkStarCont svg[data-icon="star"]:hover {
  fill: rgb(255, 91, 69);
}

.markerStar {
  justify-self: flex-end;
  align-self: flex-end;
}

.wordPlayer {
  margin: 35px 0 10px 0;
}

.starSelect svg[data-icon="star"] {
  fill: rgb(255, 91, 69);
}

@media screen and (max-height: 900px) {
  .page-container {
    height: unset;
    min-height: 100vh;
  }
  .statusBarCont {
    margin-top: 8%;
  }
}

@media screen and (max-width: 415px) {
  .page-container {
    min-height: 100vh;
    height: unset;
  }
  .btn-container {
    width: 95%;
    margin: 10px 0 5px 0;
  }
  .audioExampleBox {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    margin: 5px 5px;
  }
  .card-container {
    border-radius: unset;
    width: 95%;
  }
  .card-container::after {
    border-radius: unset;
  }
  .instructionContainer {
    display: none;
  }
  .statusBarCont {
    margin-top: 18%;
  }
  .statusBarStar {
    top: 0;
  }
  .navBar nav {
    justify-content: space-between;
    width: 90%;
  }
  .logo-txt {
    margin: unset;
  }
}

.hidden {
  display: none;
  transition: 1s;
}
.btn-container.hidden {
  display: none;
  transition: 1s;
}


.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.page-container {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  top: 0;
}

.page-container::after {
  background-image: url(http://spaceengine.org/media/2016/05/Stars02.png);
  background-size: cover;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* opacity: 0.2; */
  width: 100%;
  height: 100%;
  background-position: center;
}

.sidePanels {
  display: none;
  width: 30%;
  justify-content: center;
  align-items: center;
}
.statsBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  font-weight: 100;
  height: 40%;
}

.statHolder {
  height: 5%;
  min-height: 50px;
  min-width: 160px;
}

.statName {
  display: flex;
}

.statBarHolder {
  height: 25px;
  display: flex;
}

.statBar {
  height: 60%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}


.iconContainer {
  display: flex;
  width: 15%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 10px;
}

.iconContainer i {
  cursor: pointer;
}

.logo-txt {
  margin-left: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  color: white;
  text-shadow: 1px 1px 0px black;
  cursor: pointer;
}

.side-bar {
  position: absolute;

  display: flex;
  flex-direction: column;
  height: 94.4%;
  width: 50%;
  background: white;
  top: 50px;
  right: 0;
  z-index: 10;
  border-right: 1px black solid;
  border-bottom: 1px black solid;
  border-bottom-right-radius: 200px;
  transition: 1s;
}

.shown--sidebar {
  display: flex;
  opacity: 1;
  transition: 1s;
  right: 0;
}
.side-bar-header {
  width: 100%;
  display: flex;
  z-index: 10;
  box-shadow: 1px 1px 0.1px black;
  align-items: center;
}

.sideBarBackground {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 99.9%;
  max-width: 700px;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  background: rgba(224, 224, 224, 0.1);
  z-index: 9;
}

.dbl-right {
  margin: 5px;
  z-index: 10;
  align-self: center;
  cursor: pointer;
  position: relative;
  top: 2px;
}

svg[data-icon="double-right"]:hover {
  fill: rgb(255, 91, 69);
}

.side-bar-header h1 {
  margin: unset;
}

.items-container {
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.exHolder {
  display: flex;
  margin: 5px;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
}

.exWordContainer {
  font-weight: bold;
  width: 100%;
  display: flex;
  -webkit-user-select: none;
          user-select: none;
  background: rgba(57, 127, 255, 0.767);
  border-top-right-radius: 20px;
}
svg[data-icon="caret-right"] {
  margin-right: 2px;
  cursor: pointer;
}
svg[data-icon="caret-right"]:hover {
  fill: rgb(255, 91, 69);
}

.exampleStar {
  margin: 5px 0 0 5px;
}

.exStar {
  cursor: pointer;
}

.exampleStar svg[data-icon="star"] {
  cursor: pointer;
  position: relative;
  height: 15px;
  width: 15px;
  fill: rgb(255, 91, 69);
}

.deleteExText {
  color: black;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  position: relative;
  transition: 1s;
}

.exDefinitionContainer {
  font-style: italic;
  width: 100%;
  display: flex;
  background: rgb(212, 212, 212);
  border-bottom-left-radius: 20px;
}

.exDefinition {
  margin-left: 19px;
  margin-bottom: 3px;
  color: dimgray;
  text-align: left;
}

.card-container {
  position: relative;
  display: flex;
  padding-top: 50px;
  width: 90%;
  justify-content: center;
  align-items: center;
  max-width: 890px;
  background: rgba(75, 75, 75, 0.459);
  z-index: 1;
}

.card-container::after {
  background-image: url("https://images.pexels.com/photos/1439226/pexels-photo-1439226.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"); 
  background-size: cover;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  /* border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px; */
  z-index: -1;
}

.btn-container {
  display: flex;
  justify-content: center;
  position: relative;
  width: 330px;
  margin: 10px 0 25px 0;
}

.btn-container.mobile {
  display: none;
}

@media screen and (max-width: 415px) {
  .btn-container.mobile {
    display: flex;
  }
  .btn-container.desktop {
    display: none;
  }
}

.btn-container div {
}

.statusBarCont {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.statusBar {
  width: 50%;
  max-width: 300px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  position: relative;
}
.anticon-star {
  justify-self: flex-end;
  cursor: pointer;
}

.statusBarStar {
  position: relative;
  top: 40px;
  z-index: 5;
}

.statusBarStar svg[data-icon="star"] {
  height: 30px;
  width: 30px;
  fill: rgb(255, 223, 42);
}
.statusBarStar svg[data-icon="star"]:hover {
  fill: rgb(68, 118, 255);
}

.stats {
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  z-index: -3;
}

.cardBtn {
  color: white;
  font-size: 20px;
  font-weight: 200;
  width: 50%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 3px solid white;
}

.green {
  background: rgb(0, 197, 26);
  border-right-width: 1.5px;
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
}

.red {
  background: rgb(255, 91, 69);
  border-left-width: 1.5px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

.red:hover {
  background: rgb(255, 91, 69, 0.432);
}

.green:hover {
  background: rgba(0, 197, 26, 0.432);
}

.instructionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
 
}

.arrowContainer {
  display: flex;
  width: 80%;
  justify-content: space-around;
}

.insTitle {
  color: rgb(182, 182, 182);
  font-size: 17px;
}

.arrows svg {
  fill: white;
  height: 20px;
  width: 20px;
}
.instBox {
  display: flex;
  flex-direction: column;
}

.instr {
  color: rgb(182, 182, 182);
  font-size: 12px;
}

.modal-bg{
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  overflow: hidden;
  justify-content: center;
  align-items: center;
   
}
.modal-bg::before{
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  overflow: hidden;
  content: "";
  top: 0;
  right: 0;
  background: white;
  opacity: .3;
  -webkit-filter: blur(150px);
          filter: blur(150px);
}

.left-section{
  height: 100%;
  display: flex;
  justify-content: center;
  width: 30%;
  align-items: center;
}
.center-section{
width: 50%;
}
.right-section{
  height: 100%;
  display: flex;
  justify-content: center;
  width: 30%;
  align-items: center;
}

.complete-statbar{
  border: 4px solid gray;
  opacity: .5;
}
.complete-stat-text{
  color: gray;
  opacity: .5;
}

.left-section-content1{

}


@media screen and (max-width: 770px) {
  .statsBox {
    position: relative;
    order: 1;
    align-self: center;
    background: rgba(0, 0, 0, 0.3);
    width: 90%;
    border: 2px solid;
    padding: 10px;
    top: unset;
    left: unset;
    margin: 15px 0;
    border-radius: 5px;
  }
}

.modal{
    position: relative;
    z-index: 11;
    display: flex;
    flex-direction: column;
    height: 30%;
    padding: 5%;
    /* width: 80%; */
    justify-content: center;
    border: 1px solid black;
    border-radius: 15px;
    box-shadow: 1px 1px 2px darkslategrey;
    background: ghostwhite;
    
}

.modal-text{

}

.modal-buttons{

}

.modal-button{

}

.continue{

}


.sentences{
    border-bottom: 1px solid rgba(128, 128, 128, 0.705);
    font-size: 15px;
    color: white;
    
}
.learn-sentence-container{
display: flex;
position: relative;
flex-direction: column;
width: 100%;
background: rgba(0, 0, 0, 0.568);
border-radius: 3px;

}

.learn-sentence-header{
    width: 100%;
    padding: 3px 6px;
    display: flex;
    font-size: 13px;
    font-weight: 900;
    justify-content: center;
    color: black;
    background: rgba(255, 255, 255, 0.747);
}

.sentence-header-tab{
    padding: 2px 3px;
    width: 50%;
    display: flex;
    justify-content: center;
    border: 2px inset white;
    cursor: pointer;
    border-radius: 3px;
}

.examples-container{

}
.card-review-container{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.session-card-container{
    padding: 15px;
    height: 15%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cards-viewer{
    display: flex;
    width: 50%;
    height: 50%;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    align-items: center;
}

.card-review-card{
    border: 1px solid black;
    border-radius: 8px;
    padding: 5px;
    width: 25%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 8px 8px;
}

.exit{
    position: absolute;
    top: 0;
    right: 0; 
    cursor: pointer;
}
.note-taker{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: rgb(46, 46, 46);
}

.pad-container{
    height: 80%;
    width: 80%;
    background: rgb(95, 95, 95);
    border: 1px solid rgb(119, 119, 119);
    display: flex;
    flex-direction: column;
    border-radius: 10px;  
}

.pad-row{
    border-bottom: 1px solid rgb(0, 185, 218);
    /* height: 10vh; */
    width: 100%;
    position: relative;
    display: flex;
}

.note-block{
    height: 98%;
    border-right: 1px solid rgb(0, 185, 218);
    cursor: pointer;
}

.note-block:hover {
    background: rgb(182, 182, 182);
    border: rgb(255, 255, 96);
}
.note-taker{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: rgb(46, 46, 46);
}

.pad-container{
    position: relative;
    height: 80%;
    width: 80%;
    background: rgb(95, 95, 95);
    border: 1px solid rgb(119, 119, 119);
    display: flex;
    flex-direction: column;
    border-radius: 10px;  
}

.line-container {
    height: 100%;
    width: 50%;
    background: rgb(95, 95, 95);
    border: 1px solid rgb(119, 119, 119);
    display: flex;
    flex-direction: column; 
}

.line-input {
 height: 100%;
 width: 100%;
 background: unset;
 border: unset;
 display: none;
 background: white;
}

.line-input.active {
    display: flex;
}

.pad-row{
    border-bottom: 1px solid rgb(0, 185, 218);
    /* height: 10vh; */
    width: 100%;
    position: relative;
    display: flex;
}

.line-block{
    height: 98%;
    border-right: 1px solid rgb(0, 185, 218);
    cursor: pointer;
    position: relative;
}

/* .line-block.hov:hover {
    background: rgb(182, 182, 182);
    border: rgb(255, 255, 96);
} */
.card-select-container-fullpage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 58px;
}

.card-select-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 15px;
}



.card-select-row{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    border-top: 1px lightgray solid;
}



.row-top{
    display: flex;
    position: relative;
    width: 100%;
}

.row-bottom{
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 5px;
    
}

.card-select-header{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-select-tab {
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding: 3px 5px;
    margin: 5px 3px;
    border-radius: 8px;
    box-shadow: 0.2px 1px 1px 1px rgba(51, 51, 51, 0.459);
    cursor: pointer;
}

.card-select-tabs{
    display: flex;
    justify-content: center;
    position: relative;

}

.delete{
    cursor: pointer;
    
}

.delete-rows{
    display: flex;
}

.delete-rows > div {
    cursor: pointer;
    margin-left: .1rem;
}

.delete.crd{
    position: absolute;
    right: 0;
}

.delete.crd > svg:hover{
fill: red;
}
.select-word{

}

.swap{
    position: relative;
    top: .3rem;
}

.delete-d{

}

.select-translation{
    position: absolute;
    top: -1rem;
    color: lightslategray;
}

.select-translation.hide{
    display: none;
}

.more-cards-btn{
    cursor: pointer;
}

.selected-cards{
    display: flex;
    flex-direction: column;
    width: 75%;
    max-width: 600px;
}

.selected-cards-box{
    display: flex;
    border: 1px solid lightgray;
    border-radius: 8px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}

.regular-selected-card-container{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}
.select-card-corner{
    position: absolute;
    -webkit-clip-path: polygon(0 0, 100% 0%, 0 100%);
            clip-path: polygon(0 0, 100% 0%, 0 100%);
    top: 0;
    left: 0;
    width: 20%;
    height: 50%;
    border-top-left-radius: 8px;
}

.select-card-corner.right{
    bottom: 0;
    top: unset;
    right: 0;
    left: unset;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.select-card{
    padding: 1rem 1rem 0 1rem;
    flex-direction: column;
    cursor: pointer;
    width: 25%;
    display: flex;
    justify-content: space-around;
    position: relative;
    border-radius: 8px;
    margin: 0 .15rem;
    margin-bottom: .25rem;
    box-shadow: 0.2px 1px 1px 1px rgba(51, 51, 51, 0.459);
    font-weight: 600;
    max-width: 171px;
    
}

.select-card-word-row{
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;

}

.select-card-add-row{

}


.selected-card{
    position: relative;
    padding: 1px 5px;
    border-radius: 8px;
    min-width: 5rem;
    margin: 2px 2px;
    font-size: 14px;
    border-right-width: 5px;
    border-left-width: 5px;
    cursor: pointer;

}

.selected-word-wrapper{
   
}

.forgotten-selected-card {
    
}

.remove-selected{
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    background: rgba(255, 0, 0, 0.384);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 100%;
    width: 15%;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.432);
}

.hidden.remove-selected{
    display: none;
}
.word-type-selection-container{
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    justify-content: center;
}
.forgotten-card-container{
display: flex;
justify-content: center;
flex-wrap: wrap;
align-items: center;
flex-direction: column;
width: 100%;
}

.forgotten-header{

}

.forgotten-cards{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 50%;
    background: rgb(255, 156, 156);
    border: 5px solid red;
    border-radius: 10px;
    width: 100%;
}
.practice-container{
    position: relative;
    display: flex;
    padding-top: 50px;
    justify-content: center;
    width: 100%;
    height: 100vh;
    align-items: center;
}

.dashboard-container{
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    justify-content: center;
    width: 100%;
    height: 100vh;
    align-items: center;
}

.card-group-module {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 1px 1px 1px rgba(0,0,0,.5);
    padding: 5px;
    min-height: 250px;
    min-width: 250px;
}

.select-button {
    display: flex;
    justify-content: center;
    padding: 5px;
    align-items: center;
    box-shadow: 1px 1px 1px rgba(0,0,0,.5);
    cursor: pointer;
}
.left-section{

}

.test-results-container{

}

.test-result-box{

}

.remembered-count-container{

}

.forgotten-count-container{

}
.main-test-section{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    
}
.middle-section{
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 35%;
    flex-direction: column;
    justify-content: center;
}
.right-section {
    position: relative;
}
@media (max-width: 900px){
    .right-section{
        display: none;
    }
    .left-section{
        display: none;
    }
    .navBar{
        font-size: 16px
    }
    nav {
        justify-content: center;
    }
    .middle-section{
        width: 80%;
    }
}

.card-yard{
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px;
    margin: 10px;
    
}
.card-yard-container {
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    overflow: scroll;
}
.save-quit-button{
    
}

.view-all {
    font-weight: bold;
    box-shadow: 1px 1px 1px rgba(0,0,0,.5);


}
.view-all:hover {
    color: white;
    background: rgb(112, 112, 102);
    

}


.test-card-container{
    position: relative;
    width: 100%;
}
.mkStarCont {
    display: flex;
    position: absolute;
    margin: 0 auto;
    height: 380px;
    width: 200px;
    border-radius: 20px;
    border: 1px solid black;
    cursor: pointer;
    background: black;
    top: 0;
    align-items: flex-end;
    justify-content: center;
    z-index: 4;
  }
  
  .card {
    display: flex;
    -webkit-perspective: 500;
            perspective: 500;
    position: relative;
    margin: 0 auto;
    height: 350px;
    min-height: 350px;
    width: 200px;
    border-radius: 20px;
    border: 1px solid black;
    cursor: pointer;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: 0.6s;
    background: white;
    z-index: 5;
  }
  .basic-card {
    display: flex;
    
    position: relative;
    height: 100px;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    border-radius: 20px;
    border: 1px solid black;
    cursor: pointer;
    background: white;
    z-index: 5;
  }
  .audioExampleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  
  .front,
  .back {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: all 0.6s ease-in-out;
    -webkit-user-select: none;
            user-select: none;
    font-family: "Sawarabi Gothic", sans-serif;
    font-size: 20px;
  }
  
  .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  .front {
    flex-direction: column;
  }
  
  .flip {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .cardHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .audioExampleText {
    cursor: pointer;
    padding: 0 10px;
    min-height: 25px;
    color: white;
    font-size: 20px;
    border-radius: 25px;
    box-shadow: 1px, 1px, 1px, black;
    margin-bottom: 0px;
    text-shadow: 1px 1px 0px black;
  }
  
  .audioExampleBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80px;
  }
  
  .audioExampleBox p {
    margin: unset;
  }

  .card.wide > .front, .back{
      font-size: 12px;
  }

  .card.wide{
    width: 100%;
  }
  
  .translation {
    font-style: italic;
    transition: 1s;
  
    color: rgb(211, 211, 211);
    font-size: 18px;
    margin-top: 0px;
    border-radius: unset;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 1px solid antiquewhite;
    border-top: unset;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    max-width: 90%;
    padding: 0 10px;
  }
  
  .front.anticon-star {
    justify-self: flex-end;
    cursor: pointer;
    align-self: flex-end;
    margin: 10px;
  }
  
  .mkStarCont svg[data-icon="star"] {
    cursor: pointer;
    position: relative;
    height: 20px;
    width: 20px;
    fill: rgb(255, 223, 42);
  }
  
  .mkStarCont svg[data-icon="star"]:hover {
    fill: rgb(255, 91, 69);
  }
  
  .markerStar {
    justify-self: flex-end;
    align-self: flex-end;
  }
  
  .wordPlayer {
    margin: 35px 0 10px 0;
  }
  
  .starSelect svg[data-icon="star"] {
    fill: rgb(255, 91, 69);
  }

  .see-choices{

  }

  .answer-block{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    position: absolute;
    bottom: -10px;
    left: -10%;
    width: 110%;
    /* width: 110%; */
    background: gainsboro;
    padding: 10px;
  }

 .answer-block.hide {
      display: none;
  } 
  
  @media screen and (max-height: 900px) {
    .page-container {
      height: unset;
      min-height: 100vh;
    }
    .statusBarCont {
      margin-top: 8%;
    }
  }
  
  @media screen and (max-width: 415px) {
    .page-container {
      min-height: 100vh;
      height: unset;
    }
    .btn-container {
      width: 95%;
      margin: 10px 0 5px 0;
    }
    .audioExampleBox {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 10px;
      margin: 5px 5px;
    }
    .card-container {
      border-radius: unset;
      width: 95%;
    }
    .card-container::after {
      border-radius: unset;
    }
    .instructionContainer {
      display: none;
    }
    .statusBarCont {
      margin-top: 18%;
    }
    .statusBarStar {
      top: 0;
    }
    .navBar nav {
      justify-content: space-between;
      width: 90%;
    }
    .logo-txt {
      margin: unset;
    }
  }
  
  .hidden {
    display: none;
    transition: 1s;
  }
  .btn-container.hidden {
    display: none;
    transition: 1s;
  }
  

.left-section{

}

.test-results-container{

}

.test-result-box{

}

.remembered-count-container{

}

.forgotten-count-container{

}
.main-test-section{
    display: flex;
    justify-content: center;
}
.save-quit-button{
    
}
.tests-page-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 50px;
}

.sections-container {
  width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}


.tests-sec1 {
  width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.test-all-btn {
}

.test-session-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 350px;
  overflow: scroll;
}

.test-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 5px;
}

.test-button {
}

.test-word-count{
  display: flex;
  color: black;
  margin: 4px 4px;
  width: 2em;
  height: 2em;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 900;
  border-radius: 50%;
  border: 2px solid green;
  -webkit-user-select: none;
  user-select: none;
}

.test-word-count:hover{
  background: green;
  color: white;
}

.test-word-count.clicked{
  background: green;
  color: white;
 
}

.words-due{
  color: red;
  font-weight: bold;
}



.add-words-container{
    padding-top: 58px;
}

.word-create-container{

}
.centered-column{

    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    align-items: center;

}

.-button {
    padding: 15px 20px;
    display: flex;
    text-align: center;
    cursor: pointer;
}

.deck-icon{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centered-row{
    display: flex;
    justify-content: center;
}
