.selected-card{
    position: relative;
    padding: 1px 5px;
    border-radius: 8px;
    min-width: 5rem;
    margin: 2px 2px;
    font-size: 14px;
    border-right-width: 5px;
    border-left-width: 5px;
    cursor: pointer;

}

.selected-word-wrapper{
   
}

.forgotten-selected-card {
    
}

.remove-selected{
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    background: rgba(255, 0, 0, 0.384);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 100%;
    width: 15%;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.432);
}

.hidden.remove-selected{
    display: none;
}