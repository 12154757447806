.modal{
    position: relative;
    z-index: 11;
    display: flex;
    flex-direction: column;
    height: 30%;
    padding: 5%;
    /* width: 80%; */
    justify-content: center;
    border: 1px solid black;
    border-radius: 15px;
    box-shadow: 1px 1px 2px darkslategrey;
    background: ghostwhite;
    
}

.modal-text{

}

.modal-buttons{

}

.modal-button{

}

.continue{

}

