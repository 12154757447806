.learn-sentence-container{
display: flex;
position: relative;
flex-direction: column;
width: 100%;
background: rgba(0, 0, 0, 0.568);
border-radius: 3px;

}

.learn-sentence-header{
    width: 100%;
    padding: 3px 6px;
    display: flex;
    font-size: 13px;
    font-weight: 900;
    justify-content: center;
    color: black;
    background: rgba(255, 255, 255, 0.747);
}

.sentence-header-tab{
    padding: 2px 3px;
    width: 50%;
    display: flex;
    justify-content: center;
    border: 2px inset white;
    cursor: pointer;
    border-radius: 3px;
}

.examples-container{

}