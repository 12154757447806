.left-section{

}

.test-results-container{

}

.test-result-box{

}

.remembered-count-container{

}

.forgotten-count-container{

}
.main-test-section{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    
}
.middle-section{
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 35%;
    flex-direction: column;
    justify-content: center;
}
.right-section {
    position: relative;
}
@media (max-width: 900px){
    .right-section{
        display: none;
    }
    .left-section{
        display: none;
    }
    .navBar{
        font-size: 16px
    }
    nav {
        justify-content: center;
    }
    .middle-section{
        width: 80%;
    }
}

.card-yard{
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px;
    margin: 10px;
    
}
.card-yard-container {
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    overflow: scroll;
}
.save-quit-button{
    
}

.view-all {
    font-weight: bold;
    box-shadow: 1px 1px 1px rgba(0,0,0,.5);


}
.view-all:hover {
    color: white;
    background: rgb(112, 112, 102);
    

}


.test-card-container{
    position: relative;
    width: 100%;
}