.practice-container{
    position: relative;
    display: flex;
    padding-top: 50px;
    justify-content: center;
    width: 100%;
    height: 100vh;
    align-items: center;
}

.dashboard-container{
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    justify-content: center;
    width: 100%;
    height: 100vh;
    align-items: center;
}

.card-group-module {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 1px 1px 1px rgba(0,0,0,.5);
    padding: 5px;
    min-height: 250px;
    min-width: 250px;
}

.select-button {
    display: flex;
    justify-content: center;
    padding: 5px;
    align-items: center;
    box-shadow: 1px 1px 1px rgba(0,0,0,.5);
    cursor: pointer;
}