.select-card-corner{
    position: absolute;
    clip-path: polygon(0 0, 100% 0%, 0 100%);
    top: 0;
    left: 0;
    width: 20%;
    height: 50%;
    border-top-left-radius: 8px;
}

.select-card-corner.right{
    bottom: 0;
    top: unset;
    right: 0;
    left: unset;
    transform: rotate(180deg);
}

.select-card{
    padding: 1rem 1rem 0 1rem;
    flex-direction: column;
    cursor: pointer;
    width: 25%;
    display: flex;
    justify-content: space-around;
    position: relative;
    border-radius: 8px;
    margin: 0 .15rem;
    margin-bottom: .25rem;
    box-shadow: 0.2px 1px 1px 1px rgba(51, 51, 51, 0.459);
    font-weight: 600;
    max-width: 171px;
    
}

.select-card-word-row{
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;

}

.select-card-add-row{

}

