.card-select-container-fullpage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 58px;
}

.card-select-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 15px;
}



.card-select-row{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    border-top: 1px lightgray solid;
}



.row-top{
    display: flex;
    position: relative;
    width: 100%;
}

.row-bottom{
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 5px;
    
}

.card-select-header{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-select-tab {
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding: 3px 5px;
    margin: 5px 3px;
    border-radius: 8px;
    box-shadow: 0.2px 1px 1px 1px rgba(51, 51, 51, 0.459);
    cursor: pointer;
}

.card-select-tabs{
    display: flex;
    justify-content: center;
    position: relative;

}

.delete{
    cursor: pointer;
    
}

.delete-rows{
    display: flex;
}

.delete-rows > div {
    cursor: pointer;
    margin-left: .1rem;
}

.delete.crd{
    position: absolute;
    right: 0;
}

.delete.crd > svg:hover{
fill: red;
}
.select-word{

}

.swap{
    position: relative;
    top: .3rem;
}

.delete-d{

}

.select-translation{
    position: absolute;
    top: -1rem;
    color: lightslategray;
}

.select-translation.hide{
    display: none;
}

.more-cards-btn{
    cursor: pointer;
}

.selected-cards{
    display: flex;
    flex-direction: column;
    width: 75%;
    max-width: 600px;
}

.selected-cards-box{
    display: flex;
    border: 1px solid lightgray;
    border-radius: 8px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}

.regular-selected-card-container{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}