.forgotten-card-container{
display: flex;
justify-content: center;
flex-wrap: wrap;
align-items: center;
flex-direction: column;
width: 100%;
}

.forgotten-header{

}

.forgotten-cards{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 50%;
    background: rgb(255, 156, 156);
    border: 5px solid red;
    border-radius: 10px;
    width: 100%;
}